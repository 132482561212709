import React, { useEffect, useRef, useState } from "react";
import { Link, useNavigate } from "react-router-dom";

// import constants
import words from "../constants/words.json";

export default function Home() {
  const navigate = useNavigate();
  const animationRef = useRef(null);

  const [displayScreen, setDisplayScreen] = useState({
    width: null,
    height: null,
  });

  useEffect(() => {
    // Check if animationRef is available
    if (animationRef.current) {
      setDisplayScreen({
        width: animationRef.current.clientWidth,
        height: animationRef.current.clientHeight,
      });
    }
  }, [animationRef.current]);

  const [textLines, setTextLines] = useState([]);

  useEffect(() => {
    // Initialize textLines with the desired number of lines based on displayScreen.height
    const screenHeight = displayScreen.height || 4320; // Fallback if height is not available
    const lineCount = Math.floor(screenHeight / 27);

    setTextLines(renderRandomizedLines(lineCount));
  }, [displayScreen.height]);

  const renderRandomizedLines = (lineCount) => {
    const lines = [];

    for (let i = 0; i < lineCount; i++) {
      const wordsInLine = 20;
      let line = "";

      for (let j = 0; j < wordsInLine; j++) {
        // Randomly add spaces between words (0 to 25 spaces)
        const numSpacesPre = Math.floor(Math.random() * 30);
        line += " ".repeat(numSpacesPre);

        const randomWord = words[Math.floor(Math.random() * words.length)];
        line += randomWord;

        // Randomly add spaces between words (0 to 25 spaces)
        const numSpaces = Math.floor(Math.random() * 30);
        line += " ".repeat(numSpaces);
      }

      lines.push(line);
    }

    return lines;
  };

  useEffect(() => {
    const interval = setInterval(() => {
      const updatedLines = renderRandomizedLines(textLines.length);
      const randomLineIndex = Math.floor(Math.random() * updatedLines.length);
      const randomLine = updatedLines[randomLineIndex];
      const updatedTextLines = [...textLines];
      updatedTextLines[randomLineIndex] = randomLine;
      setTextLines(updatedTextLines);
    }, 25);

    return () => {
      clearInterval(interval);
    };
  }, [textLines]);

  return (
    <div id="lounge" className="flex flex-col min-h-screen">
      <div className="flex flex-1">
        <div className="relative w-full flex-auto">
          <div className="relative w-full h-full" ref={animationRef}>
            <svg
              className="relative block w-full h-full"
              viewBox={`0 0 undefined undefined`}
              xmlns="http://www.w3.org/2000/svg"
            >
              {textLines.map((textLine, i) => (
                <text
                  key={i}
                  x={"16"}
                  y={`${i * 27 + 16}`}
                  className="text-xl letter-spacing-0.5"
                  style={{
                    dominantBaseline: "hanging",
                    whiteSpace: "pre",

                    fill: "var(--primary-200)",
                  }}
                >
                  {textLine}
                </text>
              ))}
            </svg>
          </div>
          <div className="absolute inset-0 pointer-events-none z-70">
            <div className="absolute top-0 left-0 right-0 p-16 flex flex-row align-items-center justify-content-start z-80 pointer-events-auto">
              {/* <div className="h-30 w-30 mr-6 color-primary-[700]">
                <LogoIcon />
              </div> */}
              <span
                className="color-primary-[700] font-600 capitalize -letter-spacing-0.5 "
                style={{ fontSize: "23px" }}
              >
                DeepZero
              </span>
            </div>
            {/* <div className="absolute top-0 left-0 right-0 p-3 bg-primary-[200] flex flex-row flex-wrap align-items-center justify-content-center text-base color-primary-[500] z-80 pointer-events-auto letter-spacing-0.5">
            <div> This application is currently in its MVP stage</div>
            <div
              className="cursor-pointer ml-8 font-600"
              onClick={() =>
                // learnMoreRef.current.scrollIntoView({ behavior: "smooth" })
                {
                  navigate("/about");
                  dispatch({ type: "LANDING_PAGE_SKIP", payload: true });
                }
              }
            >
              Learn more
            </div>
          </div> */}

            <div className="absolute right-0 left-0 bottom-16 mr-auto ml-16 w-calc-[full--2*16]">
              <div className="flex flex-row -ml-16 flex-wrap">
                <div className="relative ml-16 z-90">
                  <div className="color-primary-[700] -letter-spacing-0.5 pointer-events-auto text-5xl">
                    Revolutionizing Investing with Automated Algorithms
                  </div>
                  <div className="mt-16 pointer-events-auto flex flex-row flex-wrap align-items-center">
                    {/* <div className="relative mr-16 mb-10 flex-shrink-0">
                      <Link
                        className="border border-solid border-primary-[500] relative inline-block py-10 px-16 no-underline color-black bg-white radius-999 text-base uppercase"
                        to={"/about"}
                      >
                        Learn more
                      </Link>
                    </div> */}
                    <div className="relative mr-16 mb-10 flex-shrink-0">
                      <button
                        className="inline-block relatice uppercase py-10 px-16 no-underline border border-solid border-primary-[500] color-black bg-white radius-999 outline-none border-none cursor-pointer text-base"
                        onClick={() => {
                          // dispatch({
                          //   type: "SET_REDIRECT_TO_LOUNGE_ON_STARTUP",
                          //   payload: false,
                          // });
                          // Cookies.set(
                          //   "is_redirect_to_lounge_on_startup_enabled",
                          //   "false"
                          // );
                          // navigate("/");
                        }}
                      >
                        Log in
                      </button>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div className="flex-wrap pl-16 flex-row py-10 flex relative m-0 border-t border-solid border-primary-[250]">
        <div className="pr-16 text-md break-word my-4 inline no-underline color-primary-[450] mr-auto">
          <div className="break-word">
            This system is not available to the public. It is closed to
            outsiders, primarily serving select individuals.
          </div>
        </div>

        <div className="pr-16 text-md break-word my-4 inline color-primary-[450]">
          <div className="break-word inline">© 2024 Deepence</div>
        </div>
      </div>
    </div>
  );
}
